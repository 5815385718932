<script>

    import { loadAll } from "@square/svelte-store";
    import { auth } from '$lib/auth';
    import { userInfo, season, weeks, entries } from '../stores/stores';
    import { goto } from '$app/navigation';
    import IconButton, {Icon} from '@smui/icon-button';
    import Tab, { Label } from '@smui/tab';
    import TabBar from '@smui/tab-bar';
    import {Svg} from '@smui/common/elements';
    import {mdiLogin} from '@mdi/js';
    import WeekCard from '$lib/WeekCard.svelte';
    import EntryCard from '$lib/EntryCard.svelte';
    import CreateEntry from '$lib/CreateEntry.svelte';
    import {
        mdiRefresh
    } from '@mdi/js';

    import '@splidejs/svelte-splide/css';
    import Button from '@smui/button';

    /*
    import { analytics } from '$lib/firebase';
    import { logEvent } from "firebase/analytics";

    logEvent(analytics, 'MPA_page_rendered');
    */

    let active = 'My Entries';
    let innerWidth = 0;
    let innerHeight = 0;
    const handleClick = async () => {
        goto(`/registration`);
    }

    const handleRefresh = (tab) => {
        console.log(`refreshing ${tab}`);
        if(tab === 'My Entries'){
            entries.reload();
        } else if (tab === 'Season Weeks'){
            weeks.reload();
        }
    }
</script>

<svelte:window bind:innerWidth bind:innerHeight />

    {#if $auth.user}
        {#await loadAll([userInfo, season, weeks, entries]) }
            <p>... loading</p>
        {:then}    
            {#if $userInfo.status === 'AC'}
                <h2 class="mdc-typography--headline4">Active Season: {$season}</h2>
                <Button variant="raised" href="/seasons/{$season}">View {$season} Reports</Button>
                <Button variant="raised" href="/halloffame">Hall of Fame</Button>
                {#if ($weeks[0].minGameDate.toDate() > (new Date()))}
                    <Button variant="raised" href="/invites">Invite New Players</Button>
                {/if}
                <hr />
                <TabBar tabs={['My Entries', 'Season Weeks']} let:tab bind:active>
                    <!-- Note: the `tab` property is required! -->
                    <Tab {tab}>
                      <Label>
                        {tab}
                    </Label>
                    </Tab>
                </TabBar>
                <div id="tab-container">
                    {#if active==='My Entries'}        
                        <div class="flex-container">
                            <IconButton color="secondary" aria-label="Profile" on:click={() => handleRefresh(active)} variant="raised" title="Refresh {active}">
                                <Icon component={Svg} viewBox="0 0 24 24">
                                    <path fill="currentColor" d={mdiRefresh} />
                                </Icon>
                            </IconButton>                    
                            {#if ($weeks[0].minGameDate.toDate() > (new Date()))}
                                <CreateEntry />
                            {/if}
                            {#each $entries as entry (entry.id)}
                                <EntryCard entry={entry} />
                            {/each}
                        </div>
                    {:else if active==='Season Weeks'}    
                        <div class="flex-container">
                            <IconButton color="secondary" aria-label="Profile" on:click={() => handleRefresh(active)} variant="raised" title="Refresh {active}">
                                <Icon component={Svg} viewBox="0 0 24 24">
                                    <path fill="currentColor" d={mdiRefresh} />
                                </Icon>
                            </IconButton>                                                
                            {#each $weeks as week (week.id)}
                                <WeekCard week={week} />
                            {/each}
                        </div>
                    {/if}
                </div>
            {:else}
                <h2>Thank You for Registering!</h2>
                <p>Your registration has not yet been approved, it is in progress.  You will receive a notification when it is approved.</p>
                <p>Please check back soon.</p>
            {/if}
        {/await} 
    {:else}
        <h2>Please login to access the site</h2>
        <Button variant="raised" on:click={() => goto(`/login`)}>Login</Button>
        <p>If you are new to this site, you can register here:</p>
        <Button variant="raised" on:click={()=>{handleClick()}}>Registration</Button>        
    {/if}       

<style>
    * {
      box-sizing: border-box;
    }
    
    .flex-container {
      display: flex;
      flex-wrap: wrap;
    }
    div#tab-container {
        margin: 20px;
    }
</style>



